<template>
  <div class="section-padding" id="signup">
    <div class="container">
      <div class="row m-0">
        <div class="col-lg-10 mx-auto text-white">
          <div class="card card__bg-dark br-20 px-4 px-md-5">
            <h2 class="fw-600 fs-48">
              Get ready to explore the <br />
              Finance world
            </h2>
            <p class="mt-4 mb-5 fs-20">
              Download the {{ appName }} app to get started
            </p>
            <div class="text-center text-md-start">
              <a
                href="https://play.google.com/store/apps/details?id=com.moniporte"
                target="_blank"
                class="btn btn__app-store me-0 me-md-3 p-2 d-block d-md-inline-flex"
              >
                <img src="@/assets/images/google-playstore.svg" alt="apple" />
                <span class="d-inline-block text-start fs-11 ms-2">
                  Get it on
                  <span class="d-block fw-bold fs-16">Google Play</span>
                </span>
              </a>
              <a
                href="https://testflight.apple.com/join/PKqTc5yV"
                target="_blank"
                class="btn btn__app-store p-2 mt-4 mt-md-0 d-block d-md-inline-flex"
              >
                <img src="@/assets/images/apple.svg" alt="apple" />
                <span class="d-inline-block text-start fs-11 ms-2">
                  Download on the
                  <span class="d-block fw-bold fs-16">App Store</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { appName } from "@/utils/variables";
export default {
  name: "AppStore",
  data() {
    return { appName };
  },
};
</script>

<style scoped></style>
